const jwtDecode = require("jwt-decode");



export const isAuthorized = (idToken) => {
  return typeof jwtDecode(idToken)["cognito:groups"] !== 'undefined' && (
    jwtDecode(idToken)["cognito:groups"].includes("Administrador_Banco_Imagenes") ||
    jwtDecode(idToken)["cognito:groups"].includes("Visitante_Banco_Imagenes")
  );
};



export const isAdmin = (idToken) => {
  return typeof jwtDecode(idToken)["cognito:groups"] !== 'undefined' && (jwtDecode(idToken)["cognito:groups"].includes("Administrador_Banco_Imagenes"));
};
