import React from "react"

import "./button.scss"

export default ({ type, styleType, name, icon, onClick, iconPosition,disabled }) => {
  return (
    <div onClick={onClick} className={`button ${styleType} ${icon && "icon"}`}>
      <button type={type} disabled={disabled}>{name}</button>
      {icon && <div className={icon} />}
    </div>
  )
}
