import services from './auth-service-nol'
export default class userSessionStorage {
  getToken() {
    return typeof window !== 'undefined'
      ? localStorage.getItem('TOKEN_INFO') !== null
        ? { ...JSON.parse(localStorage.getItem('TOKEN_INFO')) }
        : null
      : null
  }
  authLogin({
              payload,
              handleSuccess,
              handleError = null,
              handleFinally = null,
              saveStorage = true,
            }) {
    // payload={email,password,captcha}
    services
      .authentication(payload)
      .then(res => {
        if (res.status === 400 || res.status === 404) {
          if (handleError !== null) handleError(res)
        } else if (res.status === 200) {
          if (saveStorage) {
            localStorage.setItem(
              'TOKEN_INFO',
              JSON.stringify({
                accessToken: res.data.token.accessToken,
                expiresIn: res.data.token.expiresIn,
                idToken: res.data.token.idToken,
                tokenType: res.data.token.tokenType,
                refreshToken: res.data.token.refreshToken,
                email: res.data.email,
              })
            )
          }
          if (handleSuccess !== null) handleSuccess(res)
        } else {
          console.log('Err: ', res)
        }
      })
      .catch(err => {
        console.log(err)
        if (handleError !== null)
          handleError({ userMessage: 'Algo salió mal con la conexión.' })
      })
      .finally(() => {
        if (handleFinally !== null) handleFinally()
      })
  }
  authUserInfo({
                 handleSuccess,
                 handleError = null,
                 handleFinally = null,
                 saveStorage = true,
               }) {
    let token = this.getToken()
    if (token) {
      var tokenId = token.idToken
      var config = {
        headers: {
          Authorization: tokenId,
        },
      }
      services
        .sessionInfo(config)
        .then(res => {
          switch (res.status) {
            case 200:
              if (saveStorage) {
                localStorage.setItem('USER_INFO', JSON.stringify(res.data))
              }
              if (handleSuccess !== null) handleSuccess(res)
              break
            case 400:
              if (handleError !== null) handleError(res)
              break
            default:
              console.log(res)
              break
          }
        })
        .catch(e => {
          if (handleError !== null) handleError(e)
          console.log(e)
        })
        .finally(() => {
          if (handleFinally !== null) handleFinally()
        })
    } else {
      if (handleError !== null) handleError()
      if (handleFinally !== null) handleFinally()
    }
  }
  destroySession() {
    localStorage.removeItem('USER_INFO')
    localStorage.removeItem('TOKEN_INFO')
  }
}
