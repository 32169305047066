let url = process.env.ENVIRONMENT === 'STG' ? 'https://l60xo7vpwj.execute-api.us-east-1.amazonaws.com/stg/nol' :
  process.env.ENVIRONMENT === 'PDN' ? 'https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn/nol' :
    'https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/nol';

//authentication
export const authentication = url + "/authentication/api/v1/auth";
//account
export const accountInfo = url + "/account/api/v1/user";


// Get ip Address
export const GET_IP_ADDRESS = 'https://jsonip.com/';

// Google API KEY
export const GOOGLE_API_KEY = 'AIzaSyCN8ZrJwdNr5hJ4FkpgwMR6qzH1KS4TWlg';
export const GOOGLE_API_KEY_II = "AIzaSyBfSCVlIfp7EEmQP9r68O2ffRrpMqA1Qn0";
