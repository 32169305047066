import * as endpoints from "./../constants/api-constants";
import { axiosInstance } from './axios-instance';

class AuthService {

  static authentication(payload) {
    return axiosInstance.post(endpoints.authentication, payload).then(resp => resp.data)
  }
  static sessionInfo(config) {
    return axiosInstance.get(endpoints.accountInfo, config).then(resp => resp.data)
  }

}

export default AuthService;
