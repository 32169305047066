import { createClient } from "contentful"
const contentful = require("contentful-management")

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
  timeout: 3000,
})

const managementClient = contentful.createClient({
  accessToken: process.env.CONTENTFUL_MANAGEMENT_ACCESS_TOKEN,
})

export const createTags = async nameTag => {
  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space =>
      space.createEntry("nolBancoImagenesEtiquetas", {
        fields: {
          nombreEtiqueta: {
            "es-CO": nameTag,
          },
          titulo: {
            "es-CO": nameTag,
          },
          estado: {
            "es-CO": true,
          },
        },
      })
    )
    .then(entry => {
      entry.publish()
      console.info(`Entry ${entry.sys.id} created`)
      return true
    })
    .catch(error => {
      return error
    })
}

export const getTags = async () => {
  const response = await client
    .getEntries({
      "sys.contentType.sys.id[in]": "nolBancoImagenesEtiquetas",
      limit: 1000,
      order: "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  return response
}

export const getQueryResult = async ({
  tagId,
  limit,
  page,
  tipoContenido,
  categoryLabels,
  order,
  countries,
  tag,
}) => {
  const responseTags = await client
    .getEntries({
      content_type: "nolBancoImagenesRecurso",
      "fields.etiquetas.sys.id[in]": tagId ? tagId : 0,
      "fields.tipoContenido[in]":
        tipoContenido && tipoContenido.length > 0
          ? tipoContenido.join()
          : undefined,
      "fields.etiquetas.sys.id[all]":
        categoryLabels && categoryLabels.length > 0
          ? categoryLabels.join()
          : undefined,
      "fields.pais.sys.id[in]":
        countries && countries.length > 0 ? countries.join() : undefined,
      skip: (page - 1) * limit,
      limit: limit,
      order: order === 1 ? "sys.createdAt" : "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))
  const responseNombre = await client
    .getEntries({
      content_type: "nolBancoImagenesRecurso",
      "fields.nombre[match]": tag,
      "fields.tipoContenido[in]":
        tipoContenido && tipoContenido.length > 0
          ? tipoContenido.join()
          : undefined,
      "fields.etiquetas.sys.id[all]":
        categoryLabels && categoryLabels.length > 0
          ? categoryLabels.join()
          : undefined,
      "fields.pais.sys.id[in]":
        countries && countries.length > 0 ? countries.join() : undefined,
      skip: (page - 1) * limit,
      limit: limit,
      order: order === 1 ? "sys.createdAt" : "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  const responseDescripcion = await client
    .getEntries({
      content_type: "nolBancoImagenesRecurso",
      "fields.descripcion[match]": tag,
      skip: (page - 1) * limit,
      limit: limit,
      order: order === 1 ? "sys.createdAt" : "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  const numTotal = Math.max(
    responseTags.total,
    responseNombre.total,
    responseDescripcion.total
  )

  const numLimit = Math.max(
    responseTags.limit,
    responseNombre.limit,
    responseDescripcion.limit
  )

  const numSkip = Math.max(
    responseTags.skip,
    responseNombre.skip,
    responseDescripcion.skip
  )

  let asset = []
  if (responseTags.includes?.Asset.length > 0) {
    asset.push(...responseTags.includes.Asset)
  }
  if (responseDescripcion.includes?.Asset.length > 0) {
    asset.push(...responseDescripcion.includes.Asset)
  }
  if (responseNombre.includes?.Asset.length > 0) {
    asset.push(...responseNombre.includes.Asset)
  }

  let entry = []
  if (responseTags.includes?.Entry) {
    entry.push(...responseTags.includes.Entry)
  }
  if (responseDescripcion.includes?.Entry) {
    entry.push(...responseDescripcion.includes.Entry)
  }
  if (responseNombre.includes?.Entry) {
    entry.push(...responseNombre.includes.Entry)
  }

  const includes = {
    Asset: asset,
    Entry: entry,
  }

  let items = []

  if (responseTags.items.length > 0) {
    items.push(...responseTags.items)
  }
  if (responseNombre.items.length > 0) {
    items.push(...responseNombre.items)
  }
  if (responseDescripcion.items.length > 0) {
    items.push(...responseDescripcion.items)
  }

  const jsonData = {
    includes,
    items,
    skip: numSkip,
    limit: numLimit,
    total: numTotal,
  }
  return jsonData
}

export const getActivities = async ({ limit, page }) => {
  const response = await client
    .getEntries({
      content_type: "nolBancoImagenesRecurso",
      "fields.subcategoria.sys.contentType.sys.id[in]":
        "nolBancoImagenesSubcategoria",
      "fields.subcategoria.fields.categoria.sys.id[in]":
        "2OC66N64WKkDx1hh3dcQag",
      skip: (page - 1) * limit,
      limit: limit,
      order: "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  return response
}

export const getProducts = async ({ limit, page }) => {
  const response = await client
    .getEntries({
      content_type: "nolBancoImagenesRecurso",
      "fields.subcategoria.sys.contentType.sys.id[in]":
        "nolBancoImagenesSubcategoria",
      "fields.subcategoria.fields.categoria.sys.id[in]":
        "1k07C5FyGrTQCGPpzH0BPL",
      skip: (page - 1) * limit,
      limit: limit,
      order: "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  return response
}

/* ************************************************************************************************************/

// export const getCategory = async categoryId => {
//   const response = await client
//     .getEntries({
//       content_type: "nolBancoImagenesRecurso",
//       "fields.subcategoria.sys.contentType.sys.id[in]":
//         "nolImagesBankSubcategorias",
//       "fields.subcategoria.fields.categoria.sys.id[in]": categoryId,
//       limit: 100,
//       order: "-sys.createdAt",
//     })
//     .then(res => res)
//     .catch(error => console.error(error))

//   return response
// }

export const getCategoryNames = async () => {
  const response = await client
    .getEntries({
      content_type: "nolBancoImagenesCategoria",
      // content_type: "nolBancoImagenesSubcategoria",
      limit: 100,
      // order: "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  return response
}

export const getSubcategoryNames = async () => {
  const response = await client
    .getEntries({
      content_type: "nolBancoImagenesSubcategoria",

      limit: 100,
      // order: "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  return response
}

export const getCountries = async () => {
  const response = await client
    .getEntries({
      content_type: "nolPaises",
      limit: 100,
      // order: "-sys.createdAt",
    })
    .then(res => res)
    .catch(error => console.error(error))

  return response
}

// export const getNewest = async ({ tagId, limit, page }) => {
//   const config = {
//     content_type: "nolBancoImagenesRecurso",
//     "fields.etiquetas.sys.id[in]": tagId,
//     skip: (page - 1) * limit,
//     limit: limit,
//     order: "-sys.createdAt",
//   }
//   const response = await client
//     .getEntries(config)
//     .then(res => res)
//     .catch(error => console.error(error))

//   return response
// }

export const createAsset = async ({
  title,
  description,
  contentType,
  fileName,
  file,
}) => {
  let assetId = ""
  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space =>
      space.createAssetFromFiles({
        fields: {
          title: {
            "es-CO": title,
          },
          description: {
            "es-CO": description,
          },
          file: {
            "es-CO": {
              contentType,
              fileName,
              file,
            },
          },
        },
      })
    )
    .then(asset => asset.processForAllLocales())
    .then(asset => asset.publish())
    .then(asset => {
      console.info(`Asset ${asset.sys.id} created`)
      assetId = asset.sys.id
    })
    .catch(console.error)

  return assetId
}

export const getAsset = async ({ assetId }) => {
  let res = {}

  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space => space.getAsset(assetId))
    .then(asset => {
      console.info("ASSET", asset.fields.file["es-CO"])
      res = asset.fields.file["es-CO"]
    })

  return res
}

export const deleteAsset = async ({ assetId }) => {
  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space => space.getAsset(assetId))
    .then(asset => asset.unpublish())
    .then(asset => asset.delete())
    .then(() => console.info(`Asset  deleted`))
    .catch(console.error)
}

export const createEntry = async ({
  name,
  assetId,
  description,
  countryId,
  subcategoryId,
  labels,
  contentType,
}) => {
  let entryId = ""

  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space =>
      space.createEntry("nolBancoImagenesRecurso", {
        fields: {
          nombre: {
            "es-CO": name,
          },
          recurso: {
            "es-CO": {
              sys: {
                type: "Link",
                linkType: "Asset",
                id: assetId,
              },
            },
          },
          descripcion: {
            "es-CO": {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: description,
                      nodeType: "text",
                    },
                  ],
                  nodeType: "paragraph",
                },
              ],
              nodeType: "document",
            },
          },
          pais: {
            "es-CO": {
              sys: {
                type: "Link",
                linkType: "Entry",
                id: countryId,
              },
            },
          },
          subcategoria: {
            "es-CO": {
              sys: {
                type: "Link",
                linkType: "Entry",
                id: subcategoryId,
              },
            },
          },
          etiquetas: {
            "es-CO": labels.map(labelId => {
              return {
                sys: {
                  type: "Link",
                  linkType: "Entry",
                  id: labelId,
                },
              }
            }),
          },
          tipoContenido: {
            "es-CO": contentType,
          },
        },
      })
    )
    .then(entry => {
      console.info(`Entry ${entry.sys.id} created`)
      entryId = entry.sys.id
    })
    .catch(error => {
      return error
    })

  return entryId
}

export const editEntry = async ({
  entryId,
  name,
  assetId,
  description,
  countryId,
  subcategoryId,
  labels,
  contentType,
}) => {
  console.info("EDITING PARAMS", assetId)
  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space => space.getEntry(entryId))
    // .then(entry => {
    //   console.info("ENTRY FROM EDITING", entry)
    //   return entry
    // })
    .then(entry => entry.unpublish())
    .then(entry => {
      entry.fields.nombre["es-CO"] = name
      entry.fields.recurso["es-CO"] = {
        sys: {
          type: "Link",
          linkType: "Asset",
          id: assetId,
        },
      }
      entry.fields.descripcion["es-CO"] = {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: description,
                nodeType: "text",
              },
            ],
            nodeType: "paragraph",
          },
        ],
        nodeType: "document",
      }
      entry.fields.pais["es-CO"] = {
        sys: {
          type: "Link",
          linkType: "Entry",
          id: countryId,
        },
      }
      entry.fields.subcategoria["es-CO"] = {
        sys: {
          type: "Link",
          linkType: "Entry",
          id: subcategoryId,
        },
      }
      entry.fields.etiquetas["es-CO"] = labels.map(labelId => {
        return {
          sys: {
            type: "Link",
            linkType: "Entry",
            id: labelId,
          },
        }
      })
      entry.fields.tipoContenido["es-CO"] = contentType
      return entry.update()
    })
    .then(entry => entry.publish())
    .then(entry => console.log(`Entry ${entry.sys.id} updated.`))
    .catch(error => {
      return error
    })
}

export const publishEntry = async ({ entryId }) => {
  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space => space.getEntry(entryId))
    .then(entry => entry.publish())
    .then(entry => console.info(`Entry ${entry.sys.id} published`))
    .catch(error => {
      return error
    })
}

export const deleteEntry = async ({ entryId }) => {
  await managementClient
    .getSpace(process.env.CONTENTFUL_SPACE_ID)
    .then(space => space.getEntry(entryId))
    .then(entry => entry.unpublish())
    .then(entry => entry.delete())
    .then(() => console.info(`Entry deleted.`))
    .catch(error => {
      return error
    })
}
